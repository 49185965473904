<!--
 * @Date: 2021-03-30 15:15:40
 * @LastEditors: frank
 * @LastEditTime: 2021-03-31 11:38:08
 * @FilePath: /shop_frontend/src/views/pageDecoration/leftImgRightText.vue
 * @Description: Description
-->
<template>
  <section class="leftImgRightText">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="选择样式" prop="name">
        <a-radio-group v-model="form.style">
          <a-radio :value="0"> 左图右文 </a-radio>
          <a-radio :value="1"> 左文右图 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="图片">
        <a-upload
          name="image"
          list-type="picture-card"
          :show-upload-list="false"
          action="/api/graphic_pictures/"
          :before-upload="beforeUpload"
          :withCredentials="true"
          :headers="headers"
          @change="uploadChange"
        >
          <img
            style="display: inline-block; width: 180px; height: 180px"
            v-if="imageUrl"
            :src="imageUrl"
          />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <span style="color: red">建议上传图片尺寸180px*180px</span>
      </a-form-model-item>
      <a-form-model-item label="链接">
        <div class="flex">
          <a-input v-model="form.link" allowClear> </a-input>
          <a-button type="primary" style="margin-left: 20px" @click="openLink"
            >选择链接</a-button
          >
        </div>
      </a-form-model-item>
      <a-form-model-item label="文本内容">
        <a-textarea placeholder="文本内容" :rows="6" v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="当前排序">
        <a-input v-model="form.index" class="w220" />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否显示">
        <a-checkbox v-model="form.is_visible"></a-checkbox>
      </a-form-model-item> -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
          <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>
      </a-form-model-item>
    </a-form-model>
    <chooseLink
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    ></chooseLink>
  </section>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "leftImgRightText",
  data() {
    return {
      form: {
        style: 0,

        picture_url: "",
        picture: "",
        link: "",
        content: "",
        is_visible: false,
        index: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {},
      imageUrl: "",
      loading: false,
      visible: false,
    };
  },
  props: {
    info: {
      type: Array,
    },
  },
  components: {
    chooseLink: () => import("@/components/chooseLink"),
  },
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
  },
  watch: {
    info: {
      handler: function (newVal) {
        console.log(newVal, "-------======-");

        this.form = Object.assign({}, this.form, newVal);
        this.imageUrl = this.form.picture_url;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    openLink() {
      this.visible = true;
    },
    handleOk(list) {
      this.form.link = list[0] ? list[0].link : "";

      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    onDelete () {
      this.$axios.delete('/graphic_modules/' + this.form.id + '/').then(() => {
        this.$emit('refreshDel')
      })
    },
    onSubmit() {
      console.log(this.form, "form");
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$axios
            .put("/graphic_modules/" + this.form.id + "/", this.form)
            .then((res) => {
              console.log(res);
              this.$message.success("操作成功");
              this.$emit("refresh");
            });
          //   alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    uploadChange({ file }) {
      console.log(file);
      if (file.status == "done") {
        this.loading = false;
        this.imageUrl = file.response.image;
        this.form.picture = file.response.id;
      }
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 512;
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(512KB)，请修改后重新上传");
        return false;
      } else {
        this.loading = true;
        return true;
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>
